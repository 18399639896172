export const WebsiteEvents = {
  // https://github.com/miroapp-dev/event-schemas/blob/main/events/website_scrolled/index.md
  SCROLL: 'website_scrolled',
  // https://github.com/miroapp-dev/event-schemas/blob/main/events/website_tab_exited/index.md
  TAB_EXITED: 'website_tab_exited',
  // https://github.com/miroapp-dev/event-schemas/blob/main/events/website_heartbeat_sent/index.md
  HEARTBEAT: 'website_heartbeat_sent',
  // https://github.com/miroapp-dev/event-schemas/blob/main/events/button_clicked/index.md
  USER_CLICK: 'button_clicked',
  // https://data-portal.analytics.devrtb.com/event-catalog/events/website_performance_stat_measured/
  WEBSITE_PERFORMANCE_STAT_MEASURED: 'website_performance_stat_measured',
  // https://data-portal.analytics.devrtb.com/event-catalog/events/website_performance_page_loading_measured/
  WEBSITE_PERFORMANCE_PAGE_LOADING_MEASURED: 'website_performance_page_loading_measured',
  // https://data-portal.analytics.devrtb.com/event-catalog/events/website_performance_lcp_measured/
  WEBSITE_PERFORMANCE_LCP_MEASURED: 'website_performance_lcp_measured',
  // https://data-portal.analytics.devrtb.com/event-catalog/events/website_performance_ttfb_measured/
  WEBSITE_PERFORMANCE_TTFB_MEASURED: 'website_performance_ttfb_measured',
  // https://data-portal.analytics.devrtb.com/event-catalog/events/website_performance_fcp_measured/
  WEBSITE_PERFORMANCE_FCP_MEASURED: 'website_performance_fcp_measured',
  // https://data-portal.analytics.devrtb.com/event-catalog/events/website_performance_cls_measured/
  WEBSITE_PERFORMANCE_CLS_MEASURED: 'website_performance_cls_measured',
  // https://data-portal.analytics.devrtb.com/event-catalog/events/website_performance_inp_measured/
  WEBSITE_PERFORMANCE_INP_MEASURED: 'website_performance_inp_measured',
  // https://data-portal.analytics.devrtb.com/event-catalog/events/website_performance_fid_measured/
  WEBSITE_PERFORMANCE_FID_MEASURED: 'website_performance_fid_measured',
  // https://data-portal.analytics.devrtb.com/event-catalog/events/website_performance_tbt_measured/
  WEBSITE_PERFORMANCE_TBT_MEASURED: 'website_performance_tbt_measured',
  // https://data-portal.analytics.devrtb.com/event-catalog/events/website_performance_hydration_measured/
  WEBSITE_PERFORMANCE_HYDRATION_MEASURED: 'website_performance_hydration_measured',
  // https://data-portal.analytics.devrtb.com/event-catalog/events/website_performance_render_measured/
  WEBSITE_PERFORMANCE_RENDER_MEASURED: 'website_performance_render_measured',
}
