import {onUserActivity} from '@analytics/activity-utils'
import {onAnalyticsUnload, track} from '../analytics'
import {WebsiteEvents} from '../constants/events'

const HEARTBEAT_INACTIVITY_TIMEOUT = 10000 // 10 seconds
const HEARTBEAT_INTERVAL = 10000 // 10 seconds

export type HeartbeatEventTrackerOptions = {
  inactivityTimeout: number
  interval: number
}

export const defaultHeartbeatOptions: HeartbeatEventTrackerOptions = {
  inactivityTimeout: HEARTBEAT_INACTIVITY_TIMEOUT,
  interval: HEARTBEAT_INTERVAL,
}

class HeartbeatSender {
  private timer: NodeJS.Timer | undefined

  constructor(private readonly interval: number | undefined) {}

  start() {
    this.timer = setInterval(() => track(WebsiteEvents.HEARTBEAT), this.interval)
  }

  resume() {
    track(WebsiteEvents.HEARTBEAT)
    this.start()
  }

  stop() {
    clearInterval(this.timer)
  }
}

/**
 * This tracker is used to send heartbeat events to the analytics while the user is active.
 * It's dispatched every 10 seconds by default (configurable via options.interval).
 * It's stopped when the user is inactive for 10 seconds (configurable via options.inactivityTimeout).
 * It's resumed when the user becomes active again.
 */
export const heartbeatEventTracker = (options?: Partial<HeartbeatEventTrackerOptions>): void => {
  options = {...defaultHeartbeatOptions, ...options}

  const sender = new HeartbeatSender(options.interval)

  const activityMonitor = onUserActivity({
    timeout: options.inactivityTimeout,
    onWakeUp: sender.resume.bind(sender),
    onIdle: sender.stop.bind(sender),
  })

  sender.start()

  onAnalyticsUnload(() => {
    sender.stop()
    activityMonitor.disable()
  })
}
