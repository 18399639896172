import {Analytics, DetachListeners} from 'analytics'
import {plugins} from './plugins'

const ANALYTICS_UNLOAD_EVENT = 'unload'

const analytics = Analytics({
  app: '@miro-site/shared/web-tracking',
  plugins,
})

export const {track, identify, page, reset, on, once, storage} = analytics

// The dispatch property is not exposed in the types and we need to dispatch event so plugins might properly clean up
export const unloadAnalytics = (): void => analytics['dispatch'](ANALYTICS_UNLOAD_EVENT)

export const onAnalyticsUnload = (callback: () => void): DetachListeners =>
  on(ANALYTICS_UNLOAD_EVENT, callback)
