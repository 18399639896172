import {onScrollChange, OnScrollChangeHandlerPayload} from '@analytics/scroll-utils'
import {onAnalyticsUnload, track} from '../analytics'
import {WebsiteEvents} from '../constants/events'

export type ScrollEventTrackerOptions = {
  thresholds: number[]
}

const defaultScrollOptions: ScrollEventTrackerOptions = {thresholds: [10, 25, 50, 75, 90]}

const handler = (payload: OnScrollChangeHandlerPayload) => {
  track(WebsiteEvents.SCROLL, {scroll_depth: payload.trigger})
}

/**
 * This tracker is used to track scroll events.
 * It's dispatched when the user scrolls the page to the certain percentage of the page height.
 * The percentage is configurable via options.thresholds.
 */
export const scrollEventTracker = (options?: ScrollEventTrackerOptions): void => {
  options = {...defaultScrollOptions, ...options}

  const handlers = {}

  options.thresholds.forEach(percent => {
    handlers[percent] = handler
  })

  const removeScrollListener = onScrollChange(handlers)

  onAnalyticsUnload(removeScrollListener)
}
