import {AnalyticsPlugin} from 'analytics'
import {eventHub} from '@miro-site/analytics'
import {StatFlags} from '@mirohq-internal/stats-analytics/dist/lib/StatTypes'

type AnalyticsTrackEvent = {
  payload: {
    event: string
    properties?: Record<string, unknown>
  }
}

type AnalyticsIdentifyEvent = {
  payload: {
    userId: string
  }
}

/**
 * This plugin is used to send analytics events to the event hub.
 * It uses stats-analytics package behind the scenes.
 * @see https://github.com/miroapp-dev/stats-analytics
 */
export class EventHubAnalyticsProviderPlugin implements AnalyticsPlugin {
  [x: string]: unknown
  name = 'eventhub'

  track(data: AnalyticsTrackEvent): void {
    /**
     * In case of error, batched events aren't cleared and keep trying every 1 second.
     * This is a workaround to send events immediately in development mode and in case of error we just ignore it.
     */
    const flags =
      process.env['NODE_ENV'] === 'development' ? StatFlags.SendImmediately : StatFlags.SendInBatch

    eventHub?.track(data.payload.event, data.payload.properties, flags)
  }

  identify(data: AnalyticsIdentifyEvent): void {
    eventHub?.identify(data.payload.userId)
  }

  page(): void {
    eventHub?.page()
  }
}
