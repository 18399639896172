import {StatTypes, WebStat} from '@mirohq-internal/stats-analytics'
import {StatFlags} from '@mirohq-internal/stats-analytics/dist/lib/StatTypes'

export interface EventHubMethods {
  identify: (id: string) => void
  page: () => void
  track: (eventName: string, properties?: {[x: string]: any}, flags?: StatFlags) => void
  flushBatches: (isPageHidden?: boolean) => void
}

let eventHub: EventHubMethods
//eslint-disable-next-line @typescript-eslint/no-var-requires
const analyticsLib = require('@mirohq-internal/stats-analytics/package.json')

const params = {
  apiHost: `${process.env.NEXT_PUBLIC_ANALYTICS_EVENTHUB_ENDPOINT}/v1`,
  apiKey: process.env.NEXT_PUBLIC_ANALYTICS_EVENTHUB_AUTH,
  library: analyticsLib.name as string,
  version: analyticsLib.version as string,
}

const getEventHub = (): EventHubMethods | null => {
  // skip server side imports
  if (typeof window === 'undefined') {
    return null
  }

  if (!eventHub) {
    eventHub = getEventMethods(
      WebStat.createStatHandler({
        apiHost: params.apiHost,
        library: params.library,
        version: params.version,
        app: {
          name: process.env.NEXT_PUBLIC_SERVICE_NAME,
          version: process.env.NEXT_PUBLIC_SERVICE_VERSION,
        },
        /*
         * Send batches every 2 seconds or when 5 events are collected.
         */
        batches: {
          flushAt: 5,
          flushInterval: 2000,
        },
        addCommonStatParams: params => ({
          ...params,
        }),
        // eslint-disable-next-line no-console
        sendError: (err, extra) => window.console.error(err, extra),
      } as WebStat.WebStatOptions),
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      params,
    )

    if (!window['analytics']) {
      window['analytics'] = eventHub
    }

    const handleVisibilityChange = () => {
      const isPageHidden = document.hidden || document.visibilityState === 'hidden'

      if (isPageHidden && process.env.NEXT_PUBLIC_ANALYTICS_EVENTHUB_EXIT_EVENT) {
        eventHub.track(
          process.env.NEXT_PUBLIC_ANALYTICS_EVENTHUB_EXIT_EVENT,
          undefined,
          StatFlags.SendInBatch,
        )
      }

      eventHub.flushBatches(isPageHidden)
    }

    // Flush batches on page hide and visibility change using Beacon API
    window.addEventListener('visibilitychange', handleVisibilityChange)
    window.addEventListener('pagehide', handleVisibilityChange)
  }

  return eventHub
}

function getEventMethods(
  handler: StatTypes.StatHandler,
  params: {apiHost: string; apiKey: string},
): EventHubMethods {
  return {
    identify: (id: string) => {
      WebStat.identify(handler, params.apiKey, {id, name: '', email: ''})
    },
    page: () => {
      WebStat.page(handler, params.apiKey)
    },
    track: (eventName, properties, flags) => {
      WebStat.track(handler, params.apiKey, eventName, properties, flags)
    },
    flushBatches: (isPageHidden = false) => {
      WebStat.flushBatches(handler, isPageHidden)
    },
  }
}

export default getEventHub()
