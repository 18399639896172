import {heartbeatEventTracker} from './heartbeat'
import {scrollEventTracker} from './scroll'
import {userClickTracker} from './user-click'

export const trackers = {
  scroll: scrollEventTracker,
  heartbeat: heartbeatEventTracker,
  userClick: userClickTracker,
}

type AvailableTrackers = keyof typeof trackers

type AvailableTrackerOptions<key extends AvailableTrackers> = Parameters<typeof trackers[key]>[0]

export type UserBehaviorTrackingOptions =
  | boolean
  | {
      [key in AvailableTrackers]?: boolean | AvailableTrackerOptions<key>
    }

/**
 * Returns the options for a given tracker, based on the provided tracking options
 *
 * The trackingOptions might either be:
 * - undefined, in which case the tracker is enabled with default options
 * - a boolean [true] which enables the tracker with default options
 * - a boolean [false] which disables the tracker
 * - an object with the options for the tracker
 *
 * @param trackingOptions The tracking options provided by the user
 * @param name The name of the tracker
 */
function getTrackerOptions(trackingOptions: UserBehaviorTrackingOptions | undefined, name: string) {
  // If the tracker is not explicitly disabled as boolean false, it is enabled by default
  const enabled =
    trackingOptions === true ||
    trackingOptions?.[name] === undefined ||
    trackingOptions[name] !== false

  // If the tracker is enabled, but no options are provided, use an empty object
  const options = typeof trackingOptions?.[name] !== 'boolean' ? trackingOptions?.[name] : undefined

  return {enabled, options}
}

export function initialize(trackingOptions?: UserBehaviorTrackingOptions): void {
  if (trackingOptions === false) {
    return
  }

  for (const [name, tracker] of Object.entries(trackers)) {
    const {enabled, options} = getTrackerOptions(trackingOptions, name)

    if (!enabled) {
      continue
    }

    tracker(options)
  }
}
