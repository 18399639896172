import {addListener} from '@analytics/listener-utils'
import {onAnalyticsUnload, track} from '../analytics'
import {WebsiteEvents} from '../constants/events'

export type UserClickTrackerOptions = {
  enabled: boolean
}

const defaultOptions: UserClickTrackerOptions = {enabled: true}

/**
 * This tracker is used to track all anchor tag click events.
 * It dispatch an event when ever user click on anchor tag which have href value
 */
export const userClickTracker = (options?: UserClickTrackerOptions): void => {
  options = {...defaultOptions, ...options}
  const hasLinks = document.querySelectorAll('a').length > 0

  if (!options.enabled || !hasLinks) {
    return
  }

  const removeAnchorTagClickListner = addListener('a', 'click', (e: PointerEvent) => {
    const currentTarget = e?.currentTarget as HTMLAnchorElement
    const hrefValue = currentTarget?.href

    if (hrefValue && hrefValue !== '#') {
      let textValue = currentTarget.text
      // Remove the line breaks and arrow from the text
      textValue = textValue?.replace(/\n/g, '')?.replace(/\t/g, '')?.replace('→', '')?.trim()
      track(WebsiteEvents.USER_CLICK, {
        button_id: currentTarget.id || '',
        button_text: textValue,
        button_place: currentTarget?.dataset['place'] || '',
      })
    }
  })
  onAnalyticsUnload(removeAnchorTagClickListner)
}
